<template>
  <div>
    <h3 style="padding-top: 0.4rem;padding-bottom: 0.1rem;">您在以下哪种场景用眼更多？</h3>
    <div class="nr-box">
      <el-radio-group v-model="checkList">
        <div class="nr-content">
          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100px; height: 100px"
                  src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/crcjmax1.jpg"
                  :preview-src-list="srcList">
              </el-image>
              <p>看远距离多</p>
            </div>
            <el-radio class="fxk" label="0"></el-radio>
          </div>
          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100px; height: 100px"
                  src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/crcjmax2.jpg"
                  :preview-src-list="srcList">
              </el-image>
              <p>看中距离多</p>
            </div>
            <el-radio class="fxk" label="1"></el-radio>
          </div>
          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100px; height: 100px"
                  src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/crcjmax3.jpeg"
                  :preview-src-list="srcList">
              </el-image>
              <p>看近距离多</p>
            </div>
            <el-radio class="fxk" label="2"></el-radio>
          </div>
          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100px; height: 100px"
                  src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/crcjmax4.jpg"
                  :preview-src-list="srcList">
              </el-image>
              <p>远中切换</p>
            </div>
            <el-radio class="fxk" label="3"></el-radio>
          </div>
          <div class="nr-b-box">
            <div class="zynr">
              <el-image
                  style="width: 100px; height: 100px"
                  src="http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/crcjmax5.jpeg"
                  :preview-src-list="srcList">
              </el-image>
              <p>中近切换</p>
            </div>
            <el-radio class="fxk" label="4"></el-radio>
          </div>
        </div>
      </el-radio-group>
    </div>

    <div class="xznr">
      <p>你每天开车时间大约是多久？</p>
      <el-radio-group v-model="kc" @change="kcbh" class="xzcontent">
        <el-radio :label="1">基本不开车</el-radio>
        <el-radio :label="2">1小时以上（日常驾车）</el-radio>
        <el-radio :label="3">专职驾车</el-radio>
      </el-radio-group>
    </div>

    <div class="xznr">
      <p>你每天面对电脑、手机及其他电子产品的时间大约多久？</p>
      <el-radio-group v-model="dz" @change="dzbh" class="xzcontent">
        <el-radio :label="1">基本没有</el-radio>
        <el-radio :label="2">2小时以上（正常使用）</el-radio>
        <el-radio :label="3">4小时以上（长时间使用）</el-radio>
      </el-radio-group>
    </div>

    <div class="bz">
      <p>备注：</p>
      <el-input
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 5}"
          placeholder="请输入内容"
          v-model="postData.InquiryRemark3">
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "mostEye",
  data() {
    return {
      checkList:[],
      srcList: [
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/crcjmax1.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/crcjmax2.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/crcjmax3.jpeg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/crcjmax4.jpg",
        "http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/vppc/crcjmax5.jpeg",
      ],
      postData: {
        InquiryRemark3: '',
        InquiryFrequency: '',
        InquiryDriveTime: '',
        InquiryScene: ''
      },
      kc: -1,
      dz: -1,
      textarea2: ''
    }
  },
  watch: {
    checkList: {
      handler(newName, oldName) {
        let arr = [false,false,false,false,false]
        arr[newName] = true
        this.postData.InquiryScene = arr
      },
      immediate: true,
      deep: true
    },
    postData: {
      handler(newName, oldName) {
        if (oldName) {
          this.$store.commit('physicianVisits/UpAldult', {
            type: 4,
            obj: newName
          })
        }
      },
      immediate: true,
      deep: true
    },
    "$store.state.physicianVisits.isgetData": function (n, o) {
      this.inits()
    }
  },
  created() {

  },
  methods: {
    kcbh(e) {
      let postData = JSON.parse(JSON.stringify(this.$store.state.physicianVisits.aldultZYYY))
      if (postData.InquiryAcitivity[0] && e == 1) {
        this.kc = ''
        return this.$alert('此选项与驾驶场景冲突，请重新选择', '提示')
      }
      if ((postData.InquiryAcitivity[1] || postData.InquiryAcitivity[2]) && e == 3) {
        this.kc = ''
        return this.$alert('此选项与精准视觉或电子数码场景冲突，请重新选择', '提示')
      }
      let arr= [false, false, false]
      arr[e-1] = true
      this.postData.InquiryDriveTime = arr
    },

    dzbh(e) {
      let postData = JSON.parse(JSON.stringify(this.$store.state.physicianVisits.aldultZYYY))
      if (postData.InquiryAcitivity[0] && e == 3) {
        this.dz = ''
        return this.$alert('此选项与驾驶场景冲突，请重新选择', '提示')
      }
      if ((postData.InquiryAcitivity[1] || postData.InquiryAcitivity[2]) && e == 1) {
        this.dz = ''
        return this.$alert('此选项与精准视觉或电子数码场景冲突，请重新选择', '提示')
      }
      let arr= [false, false, false]
      arr[e-1] = true
      this.postData.InquiryFrequency= arr
    },

    inits() {
      this.checkList = ''
      this.kc = -1
      this.dz = -1

      for (let key in this.postData ) {
        this.postData[key] = this.$store.state.physicianVisits.datas[key]
      }

      if (this.postData.InquiryFrequency && this.postData.InquiryFrequency.length > 0) {
        this.postData.InquiryFrequency.forEach((item, idx) => {
          if (item) {this.kc = idx+1}
        })
      }

      if (this.postData.InquiryDriveTime && this.postData.InquiryDriveTime.length > 0) {
        this.postData.InquiryDriveTime.forEach((item, idx) => {
          if (item) {this.dz = idx+1}
        })
      }

      let arr = this.$store.state.physicianVisits.datas.InquiryScene,newarr = []
      if (arr && arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i]) {
            this.checkList = i.toString()
            break
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
 ::v-deep .fxk  .el-checkbox__inner::after {
  width: 8px;
  height: 16px;
  left: 10px;
  top: 2px;
}
 ::v-deep .fxk  .el-radio__inner {
  width: 20px;
  height: 20px;
}
::v-deep .fxk .el-radio__label {display: none;}
.nr-box {
  img {
    width: 10vw;
    border-radius: 5px;
  }
}
.nr-content {
  display: flex;
  width: 85vw;
  flex-wrap: wrap;
  padding: 0 2vw;
  .zynr {
    padding: 4px;
    border: 2px solid #8f8e8e;
    border-radius: 5px;
    position: relative;
    margin-bottom: 8px;
    p {
      position: absolute;
      bottom: 4px;
      left: 4px;
      width: calc(100% - 8px);
      height: 30%;
      background: rgba(0,0,0,0.5);
      color: #ffffff;
      font-size: 14px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .nr-b-box {
    margin:0 10px;
    padding-top: 20px;
  }
}
.xznr {
  padding: 0 3vw;
  margin-top: 0.3rem;
  width: 85vw;
  text-align: left;
  .xzcontent {
    display: flex;
    justify-content: start;
    margin-top: 0.1rem;
  }
}
.bz {
  width: 76vw;
  padding: 0 3vw;
  text-align: left;
  padding-top: 0.3rem;
}
</style>
